<template>
  <div class="container summary-page">
    <div class="row">

      <div class="col-12">
        <div v-if="cart.OrderProducts.length === 0">
          <ui-alert type="info">{{$t('cart_empty')}}</ui-alert>
        </div>
        <ui-alert type="error" v-if="error" @dismiss="error = false">
          <div v-html="error"></div>
        </ui-alert>
      </div>



      <div class="col-lg-8" v-if="cart.OrderProducts.length !== 0">


        <app-announcement position="cart" class="mb-2"></app-announcement>

        <h4 class="cart-title" @click="$router.push({name:'merchant', params:{merchantSlug:$route.params.merchantSlug}})"><i class="material-icons notranslate">arrow_back</i>Votre Commande</h4>

        <div class="mt-3" v-if="!cart.deliveryAvailable && cart.deliveryClosedReason && cart.type === 'delivery'">
          <app-delivery-unavailable-reason></app-delivery-unavailable-reason>
        </div>

        <div class="cart-content p-0 pt-3" v-if="cart.OrderProducts.length !== 0">
          <div class="cart-products">
            <div class="cart-product-item" v-if="cart.OrderProducts.length !== 0" @click="openProduct(product)" :class="{'cart-product-item-unavailable': product.product.avaible === false, 'cart-product-error': product.error}" v-for="product in cart.OrderProducts">
              <div class="quantity"><span>{{product.quantity}}</span></div>
              <div class="infos">
                <span>{{product.product.name}}</span>
                <small>{{product.option}}</small>
                <small class="comment" v-if="product.comment">{{ $t('cart_comment_exist') }}</small>
              </div>

              <div class="availability" v-if="product.categoryAvailability && product.error">
                <small>{{product.categoryAvailability.text}} uniquement</small>

              </div>

              <div class="price">
                <span v-if="product.product.avaible === true">{{product.price.price}}</span>
                <span v-else class="price-unavailable">Indisponible</span>
                <i class="material-icons notranslate" @click.stop="removeCart({productId:product.product.id, tid:product.optionIdentifier})">delete</i>
              </div>
            </div>
          </div>
          <div class="cart-comment-row" v-if="this.cart.type !== 'on_site' && !showComment" @click="showComment = true">
            <div  class="comment" v-if="!cart.comment">
              <i class="material-icons notranslate">info</i>
              <span>{{ $t('add_cart_comment') }}</span>
            </div>
            <div  class="comment" v-if="cart.comment">
              <i class="material-icons notranslate">info</i><b>{{$t('comment_kitchen')}} :</b><span>{{ cart.comment }}</span></div>
          </div>
          <div v-if="showComment" class="card-comment-container">
            <app-cart-comment></app-cart-comment>
          </div>
        </div>



      </div>


      <div class="col-lg-4" v-if="cart.OrderProducts.length !== 0">


        <!-- ORDER TYPE -->
        <div class="summary-info-list">
          <div class="summary-info summary-info-select summary-info-click">
            <div class="icon">
              <i class="material-icons notranslate" v-if="cart.type === 'delivery'">moped</i>
              <i class="material-icons notranslate" v-if="cart.type === 'pickup'">directions_run</i>
              <i class="material-icons notranslate" v-if="cart.type === 'on_site'">restaurant</i>
              <i class="material-icons notranslate" v-if="cart.type === 'on_site_counter'">storefront</i>
            </div>
            <select name="" class="details-select" v-model="cart.type" @change="changeType">
              <option value="delivery" :disabled="!cart.deliveryAvailable" v-if="merchant.options.deliveryEnabled" >{{$t('type_delivery')}}</option>
              <option value="pickup" v-if="merchant.options.pickupEnabled">{{$t('type_pickup')}}</option>
              <option value="on_site" v-if="merchant.options.onSiteEnabled">{{$t('type_on_site')}}</option>
              <option value="on_site_counter" v-if="merchant.options.onsiteCounterEnabled">{{$t('type_on_site_counter')}}</option>
            </select>
          </div>




          <!-- ORDER TIME -->
          <div class="summary-info summary-info-click" @click="$root.$emit('openOrderTime')">
            <div class="icon"><i class="material-icons notranslate">timer</i></div>
            <div class="info">
              <div v-if="cart.type === 'delivery'">
                <span v-if="cart.laterOrder && !cart.laterOrder.today">{{$t('later_at', {date:cart.laterOrder.descriptiveDate, hour: cart.laterOrder.dateHour, max:cart.laterOrder.dateHourMax})}}</span>
                <span v-if="cart.laterOrder && cart.laterOrder.today">{{$t('later_today_at', {hour: cart.laterOrder.dateHour, max:cart.laterOrder.dateHourMax})}}</span>
              </div>
              <div v-if="cart.type === 'pickup'">
                <span v-if="cart.laterOrder && !cart.laterOrder.today">{{$t('later_at_precise', {date:cart.laterOrder.descriptiveDate, hour: cart.laterOrder.dateHour})}}</span>
                <span v-if="cart.laterOrder && cart.laterOrder.today">{{$t('later_today_at_precise', {hour: cart.laterOrder.dateHour})}}</span>
              </div>
              <span v-if="!cart.laterOrder">{{$t('cart_preparation_duration', {duration:merchant.estimatedPreparationTime})}}</span>
            </div>
            <div class="material-icons notranslate chevron">keyboard_arrow_down</div>
          </div>




          <!-- ADDRESS -->
          <div class="summary-info summary-info-click" :class="{'summary-info-error': errors.some(e => e.type === 'address') && !(cart.cartDelivery && cart.cartDelivery.deliveryAvaible)}" v-if="cart.cartDelivery" @click.prevent="$root.$emit('openAddressList')" ref="addressField">
            <div class="icon"><i class="material-icons notranslate">place</i></div>
            <div class="info" v-if="cart.cartDelivery && cart.cartDelivery.address">
              <strong v-if="cart.cartDelivery.address.name">{{cart.cartDelivery.address.name}}</strong>
              <span>{{cart.cartDelivery.address.address}}</span>
              <span>{{cart.cartDelivery.address.zip}}, {{cart.cartDelivery.address.city}}</span>
              <small v-if="cart.cartDelivery && !cart.cartDelivery.deliveryAvaible" style="font-weight: 500" class="mt-1 text-danger">Votre adresse est trop éloignée du restaurant, la livraison est impossible...</small>
            </div>
            <div class="info" v-if="cart.cartDelivery && !cart.cartDelivery.address">
              <a href="#">{{ $t('order_config_set_address') }}</a>
            </div>
            <div class="material-icons notranslate chevron">keyboard_arrow_down</div>
          </div>




          <!-- ACCOUNT -->
          <div class="summary-info">
            <div class="icon"><i class="material-icons notranslate">account_circle</i></div>
            <div class="info">
              <span>{{user.firstname}} {{user.lastname}}</span>
              <span>{{user.phone}}</span>
              <span v-if="user.anonymous === false">{{user.email}}</span>
            </div>
          </div>






          <!-- PROMO CODE -->
          <div class="summary-info">
            <div class="icon"><i class="material-icons notranslate">loyalty</i></div>
            <div class="info">
              <app-discount-code></app-discount-code>
            </div>
          </div>


          <!-- META -->
          <app-cart-meta v-for="meta in cart.cartMeta" :meta="meta"></app-cart-meta>




          <!-- TOTAL DELIVERY -->
          <div class="summary-total" v-if="this.cart.type === 'delivery'">
            <div class="loading" v-if="isCartPriceLoading">
              <ui-progress-circular></ui-progress-circular>
            </div>
            <div class="price-row" :class="{'text-danger mb-0': errors.some(e => e.type === 'subtotal') && minProductValue}">
              <span>{{ $t('cart_price_sub_total') }}</span>
              <span>{{cart.values.totalProduct.price}}</span>
            </div>
            <div class="mb-3 mt-1 text-danger" style="line-height: 1" v-if="errors.some(e => e.type === 'subtotal') && minProductValue">
              <small>{{errors.find(e => e.type === 'subtotal').message}}</small>
            </div>
            <template v-if="needUnlock">
              <div class="unlock-long-distance">
                <div class="description">{{$t('cart_small_order_unlock_description', {price: cart.values.merchantUnlockSmallOrderPrice.price})}}</div>
                <div class="actions">
                  <button @click="$router.push({name:'merchant', params:{merchantSlug:$route.params.merchantSlug}})">
                    <i class="material-icons notranslate">add_shopping_cart</i>
                    <span>{{$t('cart_small_order_unlock_add_product')}}</span>
                  </button>
                  <button  @click="unlock()">
                    <i class="material-icons notranslate">login</i>
                    <span>{{$t('cart_small_order_unlock_unlock', {price:cart.values.merchantSmallOrderSupplement.price})}}</span>
                  </button>
                </div>
              </div>
            </template>
            <template v-else>
              <div class="price-row discount" v-for="discount in cart.cartDiscounts" v-if="discount.value.value !== 0 && discount.type !== 'delivery' || discount.type === 'receipt'">
                <button v-if="discount.dismissible" class="discount-remove-button" @click="removeDiscount(discount.discountId)"><i class="material-icons notranslate">close</i></button>
                <span class="discount-name">{{discount.message}}</span>
                <span v-if="discount.value.value > 0">-{{discount.value.price}}</span>
              </div>
              <div class="price-row price-row-cursor" @click="$root.$emit('openDeliveryInfo')" v-if="cart.cartDelivery && cart.cartDelivery.deliveryAvaible === true && cart.cartDelivery.addressDefined && !cart.cartDiscounts.find(d => d.type === 'delivery')">
                <span v-if="cart.values.deliveryFees.value">{{ $t('cart_price_delivery')}}<b class="pl-1">({{cart.cartDelivery.distance.distance}})</b> <i class="material-icons notranslate">info</i></span>
                <span v-if="cart.values.deliveryFees.value">{{cart.values.deliveryFees.price}}</span>
                <span v-if="cart.values.deliveryFees.value === 0">{{ $t('cart_price_delivery_free') }}<b class="pl-1">({{cart.cartDelivery.distance.distance}})</b><i class="material-icons notranslate">info</i></span>
                <span v-if="cart.values.deliveryFees.value === 0">{{ $t('cart_gifted') }}</span>
              </div>
              <div class="price-row discount price-row-cursor" @click="$root.$emit('openDeliveryInfo')" v-if="cart.cartDelivery && cart.cartDelivery.deliveryAvaible === true && cart.cartDelivery.addressDefined && cart.cartDiscounts.find(d => d.type === 'delivery')">
                <span v-if="cart.values.deliveryFees.value">{{ cart.cartDiscounts.find(d => d.type === 'delivery').message }}</span>
                <span v-if="cart.values.deliveryFees.value">{{cart.values.deliveryFees.price}}</span>
                <span v-if="cart.values.deliveryFees.value === 0">{{ cart.cartDiscounts.find(d => d.type === 'delivery').message }}<i class="material-icons notranslate">info</i></span>
                <span v-if="cart.values.deliveryFees.value === 0">{{ $t('cart_gifted') }}</span>
              </div>
              <div class="price-row text-right" v-if="cart.cartDelivery && cart.cartDelivery.deliveryAvaible === true && !cart.cartDelivery.addressDefined">
                <span>{{ $t('cart_price_delivery')}}</span>
                <small class="text-danger" style="cursor:pointer;" @click.prevent="$root.$emit('openAddressList')">{{$t('cart_set_address')}}</small>
              </div>
              <div class="price-row price-row-cursor" @click="$root.$emit('openDeliveryInfo')" v-if="cart.cartDelivery && !cart.cartDelivery.address && cart.cartDelivery.deliveryAvaible === false">
                <span>{{ $t('cart_price_delivery') }}</span>
                <span>--</span>
              </div>
              <div class="price-row" ref="addressError" v-if="cart.cartDelivery && cart.cartDelivery.address && cart.cartDelivery.deliveryAvaible === false">
                <span>{{ $t('cart_price_delivery') }}</span>
                <span><i class="material-icons notranslate error">error_outline</i></span>
              </div>
              <div class="price-row price-row-light" v-if="cart.values.merchantLongDistanceSupplement && cart.values.merchantLongDistanceSupplement.value !== 0">
                <span>+ {{ $t('cart_price_long_distance_supplement') }}</span>
                <span>{{cart.values.merchantLongDistanceSupplement.price}}</span>
              </div>
              <div class="price-row price-row-light" v-if="cart.values.merchantSmallOrderSupplement && cart.values.merchantSmallOrderSupplement.value !== 0">
                <span>+ {{ $t('cart_price_small_order_supplement') }}</span>
                <span>{{cart.values.merchantSmallOrderSupplement.price}}</span>
              </div>
              <div class="price-row price-row-cursor" @click="$root.$emit('openPlatformFeeInfo')">
                <span>{{ $t('cart_price_platform_fee') }} <i class="material-icons notranslate">info</i></span>
                <span v-if="cart.values.platformFees.value">{{cart.values.platformFees.price}}</span>
                <span v-if="cart.values.platformFees.value === 0">{{ $t('cart_gifted') }}</span>
              </div>
              <div class="price-row discount" v-if="cart.values.discount.value !== 0">
                <span>{{ $t('receipt_discount') }}</span>
                <span v-if="cart.values.discount.value > 0">-{{cart.values.discount.price}}</span>
                <span v-else-if="cart.values.discount.value === 0">{{ $t('cart_gifted') }}</span>
              </div>
              <div class="price-row total">
                <span>{{ $t('cart_price_total') }}</span>
                <span>{{cart.values.total.price}}</span>
              </div>
            </template>
          </div>



          <!-- TOTAL PICKUP, ONSITE_* -->
          <div class="summary-total" v-if="this.cart.type === 'on_site' || this.cart.type === 'pickup' || this.cart.type === 'on_site_counter'">
            <div class="loading" v-if="isCartPriceLoading">
              <ui-progress-circular></ui-progress-circular>
            </div>
            <div class="price-row" v-if="cart.cartDiscounts.length > 0">
              <span>{{ $t('cart_price_sub_total') }}</span>
              <span>{{cart.values.totalProduct.price}}</span>
            </div>
            <div class="price-row discount" v-for="discount in cart.cartDiscounts" v-if="discount.value.value !== 0 && discount.type !== 'delivery' || discount.type === 'receipt'">
              <span>{{discount.message}}</span>
              <span v-if="discount.value.value > 0">-{{discount.value.price}}</span>
            </div>
            <div class="price-row discount" v-if="cart.values.discount.value !== 0">
              <span>{{ $t('receipt_discount') }}</span>
              <span v-if="cart.values.discount.value > 0">-{{cart.values.discount.price}}</span>
              <span v-if="cart.values.discount.value === 0">{{ $t('cart_gifted') }}</span>
            </div>
            <div class="price-row total">
              <span>{{ $t('cart_price_total') }}</span>
              <span>{{cart.values.total.price}}</span>
            </div>
          </div>


        <div v-if="cart.values.cashback && cart.values.cashback.value>0" style="padding: 0 15px">
          <div class="cashback-container" @click="openCashbackModal">
            <img src="/img/party_face.png">
            <div class="cashback-content">
              <b style="margin-bottom: 2px">Vous gagnez un cashback de {{cart.values.cashback.value/100}}€</b>
              <span>Valable sur votre prochaine commande dans cet établissement</span>
            </div>
            <div>
              <i class="material-icons" style="font-size: 16px">info_outline</i>
            </div>
          </div>
        </div>


          <div class="condition-check" v-if="user">
            <ui-checkbox v-model="conditions" id="condition" class="w-100">
              <div class="d-flex justify-content-between align-items-center">
                <div style="flex: 1">
                  <span class="animate__animated condition_text" :class="{'animate__shakeX': conditionErrorAnimation, 'text-danger': errors.some(e => e.type === 'conditions') && !conditions}">
                    J'accepte les Conditions générales de ventes de Delicity
                  </span>
                </div>
                <div class="open pl-2"><a target="_blank" href="https://pro.delicity.com/cgv/">Voir<i class="material-icons notranslate">open_in_new</i></a></div>
              </div>
            </ui-checkbox>
          </div>

          <div class="p-2 pt-0" v-if="!needUnlock">
            <div v-if="cart.OrderProducts.length !== 0 && this.cart.type !== 'on_site'">
              <ui-button size="large" v-if="!this.merchant.shifts.open && merchant.activated && !cart.laterOrder" color="green" :loading="isLoading" disabled class="cart-button w-100">{{ $t('cart_order_btn_closed') }}</ui-button>
              <ui-button size="large" v-if="this.merchant.shifts.open && merchant.activated || cart.laterOrder" color="green" :loading="isLoading" @click="validateOrder" class="cart-button w-100">{{ $t('cart_order_btn') }}</ui-button>
              <ui-button size="large" v-if="!merchant.activated" color="green" :loading="isLoading" disabled class="cart-button w-100">{{ $t('no_avaible') }}</ui-button>
            </div>
            <div v-if="cart.OrderProducts.length !== 0 && this.cart.type === 'on_site'">
              <ui-button size="large" color="default" disabled class="cart-button w-100">{{ $t('on_site_payment') }}</ui-button>
            </div>
          </div>



        </div>

        <div class="d-flex justify-content-center w-100 my-3">
          <a href="https://delicity.com" target="_blank"><img src="https://delicity.b-cdn.net/front/img/new_logo_white.svg" style="height: 30px"></a>
        </div>



      </div>


    </div>

    <ui-modal ref="cashback" v-if="cart.values.cashback" v-bind="$attrs" transition="slide-top" remove-header class="panel-base">
      <div style="height: 100%; display: flex; flex-direction: column">
        <ui-toolbar class="nav" nav-icon="west" :remove-nav-icon="true" :raised="false">
          <div style="display: flex; align-items: center; justify-content: space-between; width: 100%;">
            Cashback
            <ui-icon-button
                color="black"
                class="card-button"
                size="large"
                icon="close"
                type="secondary"
                @click="closeCashback"
            ></ui-icon-button>
          </div>
        </ui-toolbar>
        <div style="display: flex; flex: 1; flex-direction: column; line-height: 1.3; justify-content: center; align-items: center; padding: 40px 15px; text-align: center">
          <img src="/img/party_face.png" style="width: 70px; margin-bottom: 20px;">
          <span style="font-size: 1.5em; font-weight: 600; margin-bottom: 10px;">En validant cette commande, vous gagnez {{ cart.values.cashback.price }} de cashback</span>
          <span style="font-size: 1.1em; color: #666">{{merchant.name}} vous offre une réduction de {{ cart.values.cashback.price }} valable sur votre prochaine commande dans cet établissement. <br>Vous recevrez le code promo directement dès la validation de la commande</span>
        </div>
        <div style="padding: 15px">
          <ui-button color="primary" @click="closeCashback" class="w-100 py-4">J'ai compris</ui-button>
        </div>
      </div>
    </ui-modal>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import CartDiscountCode from "@/desktopComponents/Merchant/CartDiscountCode";

import tippy from 'tippy.js/esm';
import 'tippy.js/themes/google.css';
import CartComment from "@/desktopComponents/Merchant/CartComment";
import Announcement from "@/mobileComponents/Merchant/Announcement";
import CartMeta from "@/desktopComponents/Merchant/CartMeta";
import DeliveryUnavailableReason from "@/desktopComponents/Merchant/DeliveryUnavailableReason";
import {$vfm} from "vue-final-modal";
import EditProduct from "@/components/Modals/Product/EditProduct";

export default {
  components: {
    'app-discount-code': CartDiscountCode,
    'app-cart-comment': CartComment,
    'app-announcement': Announcement,
    'app-cart-meta': CartMeta,
    'app-delivery-unavailable-reason': DeliveryUnavailableReason
  },
  data(){
    return {
      isLoading: false,
      errors: [],
      error: false,
      showComment: false,
      paymentMethodType: 'card',
      error_fields: [],
      isUnlock: true,
      conditionErrorAnimation: false,
      conditions: false
    }
  },
  methods: {
    ...mapActions(['openPaymentModalAction', 'initPaymentWallets', 'removeCart', 'setOrderType', 'removeDiscountCode', 'logout', 'cartSetErrorByCategory', 'setPaymentCardAction']),

    openCashbackModal(){
      this.$refs.cashback.open();
    },
    closeCashback(){
      this.$refs.cashback.close();
    },

    validateOrder(){
      this.error = false;
      this.openPaymentModalAction()
          .catch((errors) => {
            this.errors = errors;

            if(this.errors.some(e => e.type === 'conditions')){
              const el = document.getElementById('condition');
              el.scrollIntoView({behavior: 'smooth', block: 'center'});
              this.conditionErrorAnimation = true;
              setTimeout(() => {
                this.conditionErrorAnimation = false;
              }, 1000);
            }

          })
    },


    changeType(){
      this.setOrderType(this.cart.type);
    },
    validator(){
      this.error_fields = [];
      if(!this.cart.paymentMethod){
        this.error_fields.push('payment_method');
      }
      if(this.cart.cartDelivery && !this.cart.cartDelivery.addressDefined){
        this.error_fields.push('address');
      }
    },

    openProduct(cartProduct){
      if(cartProduct.selectedValuesId.length !== 0){
        cartProduct.product.options.forEach((o) => {
          o.values.forEach((v) => {
            if(cartProduct.selectedValuesId[v.id] !== undefined){
              v.checked = true;
              v.quantity = cartProduct.selectedValuesId[v.id];
            }
          });
        });
      }
      $vfm.show({component: EditProduct, bind: {cartProduct}});
      window.history.pushState({}, "",location.pathname + '#update');
    },
    removeDiscount(id){
      this.removeDiscountCode(id);
    },
    listen(){
      this.$root.$on('cancelCartComment', () => {
        this.showComment = false;
      })
    },

    unlock(){
      this.isUnlock = true;
      localStorage.setItem(this.merchant.slug + '-unlock-' + this.cart.id, '1');
    }
  },
  mounted() {
    this.initPaymentWallets();
    this.listen();

    if(this.$route.query.stripe_error){
      if(this.$te('stripe_errors.'+this.$route.query.stripe_error)){
        this.error = this.$t('stripe_errors.'+this.$route.query.stripe_error);
      }
      else{
        this.error = this.$t('stripe_errors.card_declined')
      }
    }
    if(this.cart.paymentOnSite === true){
      this.paymentMethodType = 'onsite';
    }

    this.$root.$on('3DS-authentication-failed', (e) => {
      if(e && this.$te('stripe_errors.'+e)){
        this.error = this.$t('stripe_errors.'+e)
      }
      else{
        this.error = this.$t('stripe_errors.3d_secure_failed')
      }
    })

    if(this.cart.values.merchantUnlockSmallOrderPrice && this.cart.values.merchantUnlockSmallOrderPrice.value !== 0 && (localStorage.getItem(this.merchant.slug + '-unlock-' + this.cart.id) !== '1' || !localStorage.getItem(this.merchant.slug + '-unlock-' + this.cart.id))){
      this.isUnlock = false;
    }
  },
  watch: {
    conditions(val){
      this.$store.commit('SET_CONDITION_VALIDATION', val);
    },
    cart(){
      if(this.cart.values.merchantUnlockSmallOrderPrice && this.cart.values.merchantUnlockSmallOrderPrice.value !== 0 && (localStorage.getItem(this.merchant.slug + '-unlock-' + this.cart.id) !== '1' || !localStorage.getItem(this.merchant.slug + '-unlock-' + this.cart.id))){
        this.isUnlock = false;
      }
      if(this.cart.values.merchantUnlockSmallOrderPrice && this.cart.values.merchantUnlockSmallOrderPrice.value === 0){
        localStorage.removeItem(this.merchant.slug + '-unlock-' + this.cart.id);
      }
    }
  },
  computed: {
    ...mapGetters(['cart', 'merchant', 'user', 'isCartPriceLoading']),
    minProductValue(){
      return this.cart.type === 'delivery' && this.merchant.options.minBaseOrder.value > this.cart.values.totalProduct.value;
    },
    needUnlock(){
      return this.cart
          && this.cart.type === 'delivery'
          && this.cart.values.merchantUnlockSmallOrderPrice
          && this.cart.values.merchantUnlockSmallOrderPrice.value !== 0
          && !this.isUnlock;
    }
  }
}
</script>
